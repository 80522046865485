<template>
  <div>
    <app-alert type="error" v-if="notFound">Запись не найдена</app-alert>
    <app-alert type="error" v-else-if="forbidden">План оценки заблокирован</app-alert>

    <div class="assessment-plan" v-else>
      <plans-default-skeleton v-if="isLoading" />

      <mtExpander class="assessment-plan__expander" v-else>
        <mtExpanderItem>
          <template #header>
            <h3 class="assessment-plan__expander__title">
              {{ assessmentPlan.name }}
            </h3>
            <div
              class="assessment-plan__expander__alarm"
              v-if="assessmentPlan.dateTo || assessmentPlan.dateFrom"
            >
              <mtSvg
                class="assessment-plan__expander__alarm__icon"
                name="timerfilled"
                color="#fff"
              />
              Выполнить
              <template v-if="assessmentPlan.dateFrom"
                >&nbsp;с {{ assessmentPlan.dateFrom }}</template
              >
              <template v-if="assessmentPlan.dateTo"
                >&nbsp;до {{ assessmentPlan.dateTo }}</template
              >
            </div>
          </template>
          <template #appendHeader v-if="assessmentPlan.show_progress">
            <div class="assessment-plan__expander__statistics">
              <statisticsLine
                :height="8"
                :progress="assessmentPlan.progress"
                background-color="#fff"
              />
              <slot name="headerStatistics" v-bind:item="assessmentPlan" />
            </div>
          </template>
          <template #content>
            <div class="assessment-plan__expander__text">
              {{ assessmentPlan.description }}
            </div>
          </template>
        </mtExpanderItem>
        <mtExpanderItem v-if="assessmentPlan.files?.length">
          <template #header>
            <h3 class="assessment-plan__expander__title">
              Прикрепленные файлы
            </h3>
          </template>
          <template #content>
            <file-download-card
              :links="assessmentPlan.files"
              linkValue="originalUrl"
              linkLabel="fileName"
            />
          </template>
        </mtExpanderItem>
      </mtExpander>
      <slot v-bind="assessmentPlan" />
    </div>
  </div>
</template>

<script>
import statisticsLine from '@/components/workspace/assessmentPlans/plans/statisticsLine/index.vue'
import mtLoadingAnimationLinear from '@/components/UI/mtLoadingAnimationLinear/mtLoadingAnimationLinear.vue'
import mtSvg from '@/components/UI/mtSvg/index.vue'
import mtExpanderItem from '@/components/UI/mtExpander/mtExpanderItem.vue'
import mtExpander from '@/components/UI/mtExpander/mtExpander.vue'
import { mapActions, mapState } from 'vuex'
import FileDownloadCard from '@/components/workspace/assessmentPlans/plans/fileDownloadCard/index.vue'
import PlansDefaultSkeleton from '@/components/workspace/assessmentPlans/plans/SkeletonLoaders/DefaultHeaderSkeleton.vue'
import AppAlert from '@/components/UI/AppAlert/AppAlert.vue'

export default {
  name: 'planDefault',
  components: {
    FileDownloadCard,
    mtExpander,
    mtExpanderItem,
    statisticsLine,
    mtSvg,
    mtLoadingAnimationLinear,
    PlansDefaultSkeleton,
    AppAlert,
  },
  data() {
    return {
      notFound: false,
      forbidden: false,
    }
  },
  computed: {
    ...mapState('assessmentPlans/plan', ['assessmentPlan', 'isLoading']),
  },
  methods: {
    ...mapActions('assessmentPlans/plan', ['fetchPlan']),
    ...mapActions('breadcrumbs', ['setBreadcrumbs']),

    async initBreadcrumbs() {
      if (!this.$route.meta.breadcrumbs) return

      await this.setBreadcrumbs([
        ...this.$route.meta.breadcrumbs,
        {
          value: this.assessmentPlan.name,
          link: this.$route.name,
        },
      ])
    },

    getPlan(params) {
      let data = {}
      if (params) {
        data = { settings: { ...params.sort } }
        if (Object.keys(params.filters).length) {
          data = {
            ...data,
            settings: {
              ...data.settings,
              filters: params.filters,
            },
          }
        }
        if (!!params?.search?.value) {
          data.settings['value'] = params.search.value
          if (!!params?.search?.search)
            data.settings['search'] = params.search.search
        }
      }
      this.fetchPlan({
        uuid: this.$route.params.planUuid,
        params: data,
      })
        .catch(({ status }) => {
          if (status === 404) {
            this.notFound = true
          }else if(status === 403){
            this.forbidden=true
            this.$emit('forbidden')
          }

        })
        .finally(() => {
          this.initBreadcrumbs()
        })
    },
  },
  created() {
    this.getPlan()
  },
}
</script>

<style lang="scss" src="./style.scss" />
