<template>
  <app-form-block
      :active="isInputActive"
      :errors="errors"
  >
    <template #label>
      <slot name="label"/>
    </template>

    <div
        :class="[
          'custom-textarea',
          { 'custom-textarea_disabled': disabled }
        ]"
        @click="$refs.textarea.focus()"
    >
    <textarea
        ref="textarea"
        v-model="computedModelValue"
        :maxlength="maxLength"
        :placeholder="placeholder"
        @keydown="keypress"
        @focus="focusIn()"
        @blur="focusOut()"
        :disabled ="disabled"
    />
      <div
          class="custom-textarea__length"
          v-if="maxLength"
      >
        {{(computedModelValue ?? "").length}} / {{ maxLength }}
      </div>
    </div>

    <slot />
  </app-form-block>
</template>

<script>
import AppFormBlock from "@/components/UI/AppFormBlock/AppFormBlock.vue";

export default {
  name: 'AppTextarea',
  components: { AppFormBlock },
  props: {
    disabled: Boolean,
    modelValue: String,
    maxLength: {
      type: Number,
    },
    maxHeight: {
      type: Number,
      default: 250
    },
    initHeight: {
      type: Number,
      default: 50
    },
    placeholder: {
      type: String
    },
    errors: {
      type: Array,
      default: () => []
    },
    maxLines: { type: [Number, null], default: null }
  },
  data: () => ({
    focused: false,
    touched: false,
    isInputActive: false,
    initialHeight: null
  }),
  computed: {
    computedModelValue: {
      get () {
        return this.modelValue
      },
      set (newVal) {
        this.$emit('update:modelValue', newVal)
      }
    }
  },
  methods: {
    calculateHeight () {
      const textarea = this.$refs.textarea;

      if (!this.initialHeight) {
        this.initialHeight = this.initHeight || textarea.clientHeight;
      }

      textarea.style.height = this.initialHeight + 'px';
      const newHeight = textarea.scrollHeight;

      if (newHeight > this.initialHeight && newHeight <= this.maxHeight) {
        textarea.style.height = newHeight + 'px';
      } else if (newHeight > this.maxHeight) {
        textarea.style.height = `${this.maxHeight}px`; // Максимальная высота
      } else {
        textarea.style.height = this.initialHeight + 'px'; // Минимальная высота
      }
    },

    focusIn() {
      this.isInputActive = true
    },
    focusOut() {
      if(this.computedModelValue) return;

      this.isInputActive = false
    },

    keypress(e) {
      const keyCode = e.which || e.keyCode;
      if(keyCode === 13 && !e.shiftKey && !e.metaKey) {
        e.preventDefault()
        this.$emit('submit', e)
      } else if (keyCode === 13 && e.metaKey) {
        e.preventDefault()
        this.computedModelValue += '\n'
      }
    },

    handleInput() {
      let lines = this.computedModelValue.split(/\r?\n/);
      if (lines.length > this.maxLines) {
        this.computedModelValue = lines.slice(0, this.maxLines).join('\n'); // Обрезаем
      }
    }
  },
  mounted () {
    this.isInputActive = !!this.computedModelValue;
    this.calculateHeight();
  },
  watch: {
    computedModelValue() {
      this.calculateHeight();
      this.isInputActive = !!this.computedModelValue
    }
  }
}

</script>

<style lang="scss" src="./AppTextarea.scss" />