<template>
  <component
      :is="component"
      :to="to"
      :href="href"
      :title="hint"
      :class="[
        'mt-chip',
        `mt-chip_${bgColor}`,
        {
          'mt-chip_link': isLink,
          'mt-chip_clickable': isLink,
          'mt-chip_ellipsis': ellipsis,
        }
      ]"
      :onClick="clickElement"
  >
    <slot/>
  </component>
</template>

<script>
export default {
  name: "mtChip",
  props: {
    hint: {
      type: String,
      default: ''
    },
    ellipsis: {
      type: Boolean,
      default: () => false
    },
    href: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: 'dark-green',
    },
    to: {
      type: undefined,
    },
    onHandle: {
      type: Function,
      default: null,
    },
  },
  computed: {
    isLink() {
      return !!this.to || !!this.href;
    },
    component() {
      if(this.isLink) return this.to ? "router-link" : "a";

      return "div";
    },
  },
  methods: {
    clickElement(event) {
      if (this?.onHandle && event.currentTarget.innerHTML.trim()) {
        this.onHandle(event.currentTarget.innerHTML.trim())
      }
    }
  }
}
</script>

<style
  lang="scss"
  scoped
>
.mt-chip {
  height: 24px;
  border-radius: 100px;
  font-weight: 800;
  font-size: 11px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0 11px;
  background-color: $brown;
  width: fit-content;
  display: block;
  line-height: 24px;
  &_clickable {
    cursor: pointer;
  }
  &_ellipsis {
    white-space: nowrap;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &_brown {
    background-color: $brown;
  }
  &_light-brown {
    background-color: $light-brown;
    color: $dark-brown;
  }

  &_dark-brown {
    background-color: #ae9489;
    color: #fff;
  }

  &_dark {
    background-color: $dark;
  }

  &_gray {
    background-color: $gray;
  }
  &_green {
    background-color: $green;
    color: #fff;
  }
  &_light-green {
    background-color: $light-green;
    color: $green;
  }
  &_red {
    background-color: $red;
  }
  &_light-red {
    background-color: $light-red;
    color: $red;
  }
}
</style>