<template>
  <app-select
    ref="selectRef"
    v-bind="$attrs"
    :active="searchText?.length ? true : false"
    :items="computedItems"
    :item-text="itemText"
    :item-value="itemValue"
    :disabled="disabled"
    :clearClick="clearClick"
    v-model="computedModelValue"
    @change="onChange"
  >
    <template #header>
      <input
        type="text"
        v-model="searchText"
        :disabled="disabled"
        class="app__autocomplete__input"
        @focus="selectRef?.activateOptions()"
      />
    </template>
    <template #item="item">
      <slot name="item" :item="item" />
    </template>
  </app-select>
</template>

<script lang="ts">
import { ref, computed, watchEffect, watch, onMounted, defineComponent } from 'vue'
import AppSelect from '../AppSelect/AppSelect.vue'

export default defineComponent({
  components: { AppSelect },
  props: {
    modelValue: { type: null, default: null },
    items: { type: Array, default: () => [] },
    itemText: { type: String, default: 'label' },
    itemValue: { type: String, default: 'value' },
    disabled: { type: Boolean, default: false },
    clearClick: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const searchText = ref('')
    const selectRef = ref<InstanceType<typeof AppSelect> | null>(null)

    const computedModelValue = computed({
      get: () => props.modelValue,
      set: (newValue) => emit('update:modelValue', newValue),
    })

    const computedItems = computed(() =>
      !searchText.value.length
        ? props.items
        : props.items.filter((item) => {
            const value = (item as Record<string, any>)[props.itemText] as string
            return value?.toLowerCase().includes(searchText.value.toLowerCase())
          })
    )

    const updateSearchText = (value: any) => {
      if (value && props.items.length) {
        const item = props.items.find(
          (item) => (item as Record<string, any>)[props.itemValue] === value
        )
        searchText.value = item ? (item as Record<string, any>)[props.itemText] : ''
      }
    }

    watchEffect(() => updateSearchText(computedModelValue.value))

    onMounted(() => updateSearchText(computedModelValue.value))

    watch(computedModelValue, (newValue) => {
      if (newValue === null) searchText.value = ''
    })

    const onChange = () => {
      emit('change')
    }

    return { searchText, selectRef, computedModelValue, computedItems, onChange }
  },
})
</script>

<style lang="scss" src="./AppAutocomplete.scss"></style>
