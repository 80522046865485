<template>
  <div
    :class="[
      'app-form-block',
      {
        'app-form-block_half': props.half,
        'app-form-block_hide-details': props.hideDetails
      }
    ]"
  >
    <div
      :class="[
        'app-form-block__label',
        {
          'app-form-block__label--active': props.active
        }
      ]"
      :style="props.labelStyle"
    >
      <slot name="label" />
    </div>

    <slot />

    <div
      v-if="errors.length > 0"
      class="app-form-block__error"
    >
      {{ errors[0].$message }}
    </div>
  </div>
</template>

<script setup lang="ts">
interface Props {
  errors?: any[]
  hideDetails?: boolean
  half?: boolean
  active?: boolean
  labelStyle?: Record<string, string> | string
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
  hideDetails: false,
  half: false,
  active: false,
  labelStyle: ''
})
</script>

<style lang="scss" src="./AppFormBlock.scss" />